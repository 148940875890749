$(window).ready(function () {
    $('nav a[href*="#"]:not([href="#"])').click(function () {
        //        Если не использовать scroll active

        //        $('a').each(function () {
        //            $(this).removeClass('active');
        //        })
        //        $(this).addClass('active');
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 0
                }, 1000);
                return false;
            }
        }
    });

    $("button.burger").click(function(){
        $(this).toggleClass("active");
        $('.menu__wrap nav').toggleClass("active");
        $('body').toggleClass("over_hide");
    });
    $("nav ul li a").click(function(){
        $('button.burger').removeClass("active");
        $('.menu__wrap nav').removeClass("active");
        $('body').removeClass("over_hide");
    });

    // Заказать звонок
    $("#call__me").iziModal({
        title: 'Обратный звонок',
        subtitle: 'Прокат автомобилей в Санкт-Петербурге',
        borderBottom: false,
        width: 360,
        padding: 15
    });
    $("#ask__buy").iziModal({
        title: 'Заявка на заказ',
        subtitle: 'Прокат автомобилей в Санкт-Петербурге',
        borderBottom: false,
        width: 460,
        padding: 15
    });
    $("#car_1,#car_2,#car_3,#car_4,#car_5,#car_6,#car_7,#car_8,#car_9").iziModal({
        title: 'Заказ автомобиля',
        subtitle: 'Прокат автомобилей в Санкт-Петербурге',
        borderBottom: false,
        width: 460,
        padding: 15
    });
    $("#ask__question").iziModal({
        title: 'Задать вопрос',
        subtitle: 'Прокат автомобилей в Санкт-Петербурге',
        borderBottom: false,
        width: 460,
        padding: 15
    });
});

$(document).ready(function(){

    $('.phone_us').mask('+7 (000) 000-00-00');

    $('#btn_submit').click(function(){
        // собираем данные с формы
        var user_name 	    = $('#user_name').val();
        var user_phone 	    = $('#user_phone').val();
        // отправляем данные
        $.ajax({
            url: "form/callme.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name": 	user_name,
                "user_phone": 	user_phone
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-call').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_buy').click(function(){
        // собираем данные с формы
        var user_name_buy 	    = $('#user_name_buy').val();
        var user_phone_buy 	    = $('#user_phone_buy').val();
        // отправляем данные
        $.ajax({
            url: "form/buy.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_buy": 	user_name_buy,
                "user_phone_buy": 	user_phone_buy
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-buy').html(data.result); // выводим ответ сервера
            }
        });
    });

    $('#btn_submit_car_1').click(function(){
        // собираем данные с формы
        var user_name_car_1 	    = $('#user_name_car_1').val();
        var user_car_car_1 	    = $('#user_car_car_1').val();
        var user_phone_car_1    = $('#user_phone_car_1').val();
        // отправляем данные
        $.ajax({
            url: "form/car.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_1": 	user_name_car_1,
                "user_car_car_1": user_car_car_1,
                "user_phone_car_1": 	user_phone_car_1,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_1').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_2').click(function(){
        // собираем данные с формы
        var user_name_car_2 	    = $('#user_name_car_2').val();
        var user_car_car_2 	    = $('#user_car_car_2').val();
        var user_phone_car_2    = $('#user_phone_car_2').val();
        // отправляем данные
        $.ajax({
            url: "form/car2.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_2": 	user_name_car_2,
                "user_car_car_2": user_car_car_2,
                "user_phone_car_2": 	user_phone_car_2,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_2').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_3').click(function(){
        // собираем данные с формы
        var user_name_car_3 	    = $('#user_name_car_3').val();
        var user_car_car_3 	    = $('#user_car_car_3').val();
        var user_phone_car_3    = $('#user_phone_car_3').val();
        // отправляем данные
        $.ajax({
            url: "form/car3.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_3": 	user_name_car_3,
                "user_car_car_3": user_car_car_3,
                "user_phone_car_3": 	user_phone_car_3,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_3').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_4').click(function(){
        // собираем данные с формы
        var user_name_car_4 	    = $('#user_name_car_4').val();
        var user_car_car_4 	    = $('#user_car_car_4').val();
        var user_phone_car_4    = $('#user_phone_car_4').val();
        // отправляем данные
        $.ajax({
            url: "form/car4.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_4": 	user_name_car_4,
                "user_car_car_4": user_car_car_4,
                "user_phone_car_4": 	user_phone_car_4,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_4').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_5').click(function(){
        // собираем данные с формы
        var user_name_car_5 	    = $('#user_name_car_5').val();
        var user_car_car_5 	    = $('#user_car_car_5').val();
        var user_phone_car_5    = $('#user_phone_car_5').val();
        // отправляем данные
        $.ajax({
            url: "form/car5.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_5": 	user_name_car_5,
                "user_car_car_5": user_car_car_5,
                "user_phone_car_5": 	user_phone_car_5,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_5').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_6').click(function(){
        // собираем данные с формы
        var user_name_car_6 	    = $('#user_name_car_6').val();
        var user_car_car_6 	    = $('#user_car_car_6').val();
        var user_phone_car_6    = $('#user_phone_car_6').val();
        // отправляем данные
        $.ajax({
            url: "form/car6.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_6": 	user_name_car_6,
                "user_car_car_6": user_car_car_6,
                "user_phone_car_6": 	user_phone_car_6,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_6').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_7').click(function(){
        // собираем данные с формы
        var user_name_car_7 	    = $('#user_name_car_7').val();
        var user_car_car_7 	    = $('#user_car_car_7').val();
        var user_phone_car_7    = $('#user_phone_car_7').val();
        // отправляем данные
        $.ajax({
            url: "form/car7.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_7": 	user_name_car_7,
                "user_car_car_7": user_car_car_7,
                "user_phone_car_7": 	user_phone_car_7,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_7').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_8').click(function(){
        // собираем данные с формы
        var user_name_car_8 	    = $('#user_name_car_8').val();
        var user_car_car_8 	    = $('#user_car_car_8').val();
        var user_phone_car_8    = $('#user_phone_car_8').val();
        // отправляем данные
        $.ajax({
            url: "form/car8.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_8": 	user_name_car_8,
                "user_car_car_8": user_car_car_8,
                "user_phone_car_8": 	user_phone_car_8,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_8').html(data.result); // выводим ответ сервера
            }
        });
    });
    $('#btn_submit_car_9').click(function(){
        // собираем данные с формы
        var user_name_car_9 	    = $('#user_name_car_9').val();
        var user_car_car_9 	    = $('#user_car_car_9').val();
        var user_phone_car_9    = $('#user_phone_car_9').val();
        // отправляем данные
        $.ajax({
            url: "form/car9.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_car_9": 	user_name_car_9,
                "user_car_car_9": user_car_car_9,
                "user_phone_car_9": 	user_phone_car_9,
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-car_9').html(data.result); // выводим ответ сервера
            }
        });
    });

    $('#btn_submit_question').click(function(){
        // собираем данные с формы
        var user_name_question 	    = $('#user_name_question').val();
        var user_phone_question 	    = $('#user_phone_question').val();
        var text_comment_question    = $('#text_comment_question').val();
        // отправляем данные
        $.ajax({
            url: "form/question.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_question": 	user_name_question,
                "text_comment_question": text_comment_question,
                "user_phone_question": 	user_phone_question
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-question').html(data.result); // выводим ответ сервера
            }
        });
    });
});